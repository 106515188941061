import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Header = () => {
    const [searchId, setSearchId] = useState('');
    const navigate = useNavigate();

    const handleSearch = (e) => {
        if (e.key === 'Enter') {
            if (searchId.trim()) {
                navigate(`/${searchId.trim()}`); // Navigiere zur ID-Route
            }
        }
    };

    return (
        <header className="bg-gray-900 text-white py-4">
            <div className="container mx-auto flex flex-col items-center md:flex-row md:justify-between md:items-center">
                <div className="flex items-center mb-4 md:mb-0">
                    <img src="/ascalter.png" alt="Logo" className="w-10 h-10 mr-2" />
                    <h1 className="text-xl font-bold">Ascalter.de</h1>
                </div>
                <div className="relative w-full md:w-1/2">
                    <input
                        type="text"
                        value={searchId}
                        onChange={(e) => setSearchId(e.target.value)}
                        onKeyDown={handleSearch}
                        placeholder="Suche nach ID..."
                        className="w-full p-2 rounded-lg bg-gray-800 text-white border border-gray-700 focus:outline-none focus:border-blue-500"
                    />
                </div>
            </div>
        </header>
    );
};

export default Header;