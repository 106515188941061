import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { useLocation } from 'react-router-dom';

const Layout = ({ children }) => {
    const location = useLocation();

    const isCenteredPage = location.pathname === '/';

    return (
        <div className="flex flex-col min-h-screen bg-gray-800 text-white">
            <Header />
            <main className={`flex-grow ${isCenteredPage ? 'flex items-center justify-center' : ''} p-6`}>
                {children}
            </main>
            <Footer />
        </div>
    );
};

export default Layout;