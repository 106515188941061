import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

const ChatLog = () => {
    const { id } = useParams();
    const [chatLog, setChatLog] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        const fetchMessages = async () => {
            setChatLog(null);
            setError(false);
            setLoading(true);

            if (id) {
                try {
                    const response = await fetch(`https://chatlog.ascalter.de/api/chat-logs/${id}`);
                    if (!response.ok) throw new Error('Nachrichten konnten nicht abgerufen werden.');

                    const data = await response.json();
                    if (!data) {
                        setError(true);
                    } else {
                        setChatLog(data);
                    }
                } catch (e) {
                    setError(true);
                } finally {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        };

        fetchMessages();
    }, [id]);

    if (error) {
        return (
            <div className="flex flex-col items-center justify-center h-full text-center">
                <div className="bg-gray-700 p-8 rounded-lg shadow-lg">
                    <h1 className="text-3xl mb-4">ID nicht gefunden!</h1>
                    <p className="text-lg mb-4">Die eingegebene ID konnte nicht gefunden werden.</p>
                    <p className="text-sm">Bitte versuche es erneut oder gehe zurück zur <a href="/" className="text-blue-400 underline">Startseite</a>.</p>
                </div>
            </div>
        );
    }

    if (loading) return <div className="text-center text-white">Nachrichten werden abgerufen...</div>;

    if (!chatLog) return null;

    let jsonChatLogEntities;

    try {
        jsonChatLogEntities = JSON.parse(chatLog.jsonChatLogEntities);
        jsonChatLogEntities = jsonChatLogEntities.filter(entity => entity.server === chatLog.reporterServer);
    } catch (e) {
        return <div className="flex flex-col items-center justify-center h-full text-center">
            <div className="bg-gray-700 p-8 rounded-lg shadow-lg">
                <h1 className="text-3xl mb-4">ID nicht gefunden!</h1>
                <p className="text-lg mb-4">Die eingegebene ID konnte nicht gefunden werden.</p>
                <p className="text-sm">Bitte versuche es erneut oder gehe zurück zur <a href="/"
                                                                                        className="text-blue-400 underline">Startseite</a>.
                </p>
            </div>
        </div>;
    }

    return (
        <div className="p-4 space-y-2">
            <h1 className="text-3xl mb-1">Chatlog ID: {id}</h1>
            <h2 className="text-xl mb-4">Server: {chatLog.reporterServer}</h2>
            {jsonChatLogEntities.map((entity, index) => (
                <div
                    key={index}
                    className={`bg-gray-800 px-3 py-2 rounded flex flex-col md:flex-row items-start md:items-center space-x-2 md:space-x-4 font-minecraft text-base text-white ${entity.sender === chatLog.targetUniqueId ? 'border border-red-400 bg-red-800 bg-opacity-25' : 'md:border-none'} md:space-y-0 space-y-2 border border-gray-500`}
                >
                    <span className="text-gray-400 text-sm md:text-base md:mr-4">
                    [{new Date(entity.timeStamp).toLocaleString('de-DE', {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit',
                        second: '2-digit'
                    })}]
                    </span>

                    <div className="flex items-center space-x-2">
                        <img
                            src={`https://crafatar.com/avatars/${entity.sender}?size=30&overlay`}
                            alt="Avatar"
                            className="w-8 h-8 md:w-10 md:h-10 rounded-sm"
                        />

                        <div className="flex flex-col md:flex-row md:items-center space-y-1 md:space-y-0 md:space-x-2">
                            <span className="text-gray-400 font-bold text-sm md:text-base">{entity.senderName}</span>
                            <span className="text-white text-sm md:text-base">{entity.message}</span>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default ChatLog;
