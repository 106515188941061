import React from 'react';

const Footer = () => {
    const currentYear = new Date().getFullYear();

    return (
        <footer className="bg-gray-900 text-white py-4">
            <div className="container mx-auto flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
                <div className="text-right md:order-1">
                    <p>Made with ❤️ by xXSwainXx</p>
                </div>
                <div className="text-center md:order-2">
                    <p className="text-lg">Copyright © Ascalter.de {currentYear}</p>
                </div>
                <div className="flex space-x-4 md:order-3">
                    <a href="https://forum.ascalter.de/terms/" className="hover:underline">Datenschutz</a>
                    <a href="https://forum.ascalter.de/imprint/" className="hover:underline">Impressum</a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;