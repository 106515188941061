import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './Layout';
import ChatLog from './ChatLog';

const App = () => {
    return (
        <Router>
            <Layout>
                <Routes>
                    <Route path="/:id" element={<ChatLog />} />
                    <Route
                        path="/"
                        element={
                            <div className="flex items-center justify-center h-full text-center">
                                <h1 className="text-3xl mb-4">Bitte suche nach einer ID</h1>
                            </div>
                        }
                    />
                </Routes>
            </Layout>
        </Router>
    );
};

export default App;